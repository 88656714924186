.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login-container form {
  display: grid;
  grid-template-columns: 5em 1fr;
  row-gap: 0.5em;
  width: 50%;
  max-width: 400px;
  min-width: 100px;
}

.login-container form {
  label,
  input[type="text"] {
    display: block;
    margin-top: auto;
    margin-bottom: auto;
  }

  label[for="remember"] {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    text-align: center;
  }

  button[type="submit"] {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 3;
  }

  .error {
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: center;
    color: #ff8b8b;
  }
}

#root {
  height: 100%;
}

body,
html {
  height: 100%;
}

body {
  background-color: #121412;
  color: #ccd4e2;
  font-family: "sans-serif";
}

input[type="text"],
input[type="password"],
button {
  border: 1px solid;
  background-color: #121412;
  border-color: #ccd4e2;
  border-radius: 5px;
  color: #ccd4e2;
  padding: 3px 5px;
}

button {
  font-size: 1em;
  background-color: #232423;
  transition: background-color 0.5s;
}

button:hover {
  background-color: #3a3e3a;
}

p.loading {
  text-align: center;
}

.navbar {
  text-align: right;
  padding: 0.5em;
  background: #222;
}

.host-table {
  border: none;
  border-collapse: collapse;
  width: 100%;
  text-align: center;

  thead {
    background: #222;

    th {
      padding: 0.3em 0;
    }
  }

  td {
    padding: 0.2em;
    padding-top: 0.6em;
  }

  td.ip-info {
    text-align: left;
  }

  td.host-name a {
    color: #ccd4e2;
    text-decoration: none;
    font-weight: bold;
  }

  td.ip-info {
    text-align: center;
  }

  table.ip-info {
    td {
      padding: 0.1em;
    }

    th {
      color: #80848a;
      font-weight: normal;
      font-size: 0.9em;
      text-align: right;
    }

    td {
      text-align: left;
    }
    td.ip {
      font-weight: bold;
    }

    tr.warning {
      th {
        color: #957575;
      }
      td {
        color: #ff8b8b;
      }
    }
  }
}

.content {
  overflow: auto;
}

@media only screen and (max-width: 800px) {
  .host-table {
    > thead {
      display: none;
    }
    tr,
    td,
    th {
      text-align: left;
      display: block;
    }

    table.ip-info th {
      text-align: left;
    }

    td.ip-info.type-ipv4 {
      > .disabled::before {
        content: "IPv4 ";
      }
    }

    td.ip-info.type-ipv6 {
      > .disabled::before {
        content: "IPv6 ";
      }
    }

    .host-name {
      text-align: center;
      background: #222;
      margin-top: 1em;
      padding: 0.5em;
    }

    tr:first-of-type .host-name {
      margin-top: 0;
    }
  }
}
